<template>
  <div id="tooltip-menu-bottom" class="wrapper-menu-bottom">
    <client-only>
      <div class="wrapper-menu-bottom__content">
        <MenuItem v-for="(item, index) in menus" :key="item.icon" :item="item" :class="`menu-item--${index}`" />
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import MenuItem from './menu-item.vue'
import { PAGE_URLS } from '~/config/page-url'
import { BOTTOM_MENU } from '~/constants/menu'

const route = useRoute()
const menus = computed(() => {
  return BOTTOM_MENU.filter((item) => item.name !== (route.path !== PAGE_URLS.PROMOTION ? 'Trang chủ' : 'Khuyến mãi'))
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/index.scss" />
